<template>
            <v-snackbar
               rounded   = "pill"
               v-model   = "MySnackBar.show"
               timeout   = "2500"
               top
               middle
               color   = "primary"
               elevation="24"
            >  

             <v-icon v-if = "MySnackBar.showImage == 'icon'">
                 mdi-alert-circle-outline
             </v-icon>
             <span class="ml-2 message">{{ this.MySnackBar.text }}</span>
        
             <v-progress-circular
                 v-if = "MySnackBar.showImage == 'progress'"
                 indeterminate
                 color="primary"
             ></v-progress-circular>

            </v-snackbar>
</template>

<script>
   export default {
     props : { "MySnackBar" : Object },
  }
</script>

<style scoped>
   .message{
   color: white; 
   font-family: 'Roboto';
   font-size: 16px;
   }
</style>