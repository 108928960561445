<template>
        <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y 
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
             <v-text-field
                :label    = "caption"
                :disabled = "!enabled"
                dense
                outlined
                readonly
                clearable
                v-on   = "on"
                :value = "curDate"
              ></v-text-field>
            </template>

            <v-date-picker
              locale    = "en-in"
              :value    = "curDate"
              no-title
              @input   = "dateSelected"
            ></v-date-picker>
       </v-menu>
</template>

<script>
  export default {
    props      : [ 'curDate', 'caption','enabled'],
    methods    : {
                 dateSelected(e) {
                      this.fromDateMenu = false;
                      this.$emit('updated', e)
                 }
    },

    data() {
      return {
          fromDateMenu  : false,
      };
    },
  };
</script>