 <template>
        <v-card >
                    <v-card-title class="primary white--text">Engineer Signature</v-card-title>
                      <v-card-text class="pt-4">
                          <v-row>
                           
                             <v-col cols="12">
                              <v-text-field  v-model = "name"
                                             outlined
                                             dense
                                             label   = "Engineer Name"> </v-text-field>
                             </v-col>

                            <v-col cols="12">
                               <DateSelector 
                                        caption        = "Date" 
                                        v-bind:enabled = "true"
                                        v-bind:curDate = "date"
                                        @updated       = "(e) => {date = e}"
                                ></DateSelector>
                            </v-col>

                          </v-row>

                          <v-row dense> 
                            <v-col cols="12">
                               <div class="d-flex justify-center"><strong>Signature:</strong></div> 
                            </v-col>
                          </v-row>

                          <v-row dense> 
                            <v-col cols="12">
                              <div class="d-flex justify-center">
                              <VueSignaturePad
                                   id     ="signature"
                                   width  ="300px"
                                   height ="90px"
                                   ref    ="signaturePad"
                                   :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
                              />
                              </div>
                            </v-col>
                          </v-row>

                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions >
                           <v-btn  color="primary" text @click="cancelSignature">Cancel</v-btn>
                           <v-btn  color="primary" text @click="resetSignature">Reset</v-btn>
                           <v-spacer></v-spacer>
                           <v-btn  color="primary" text @click="continueSignature">Continue</v-btn>
                        </v-card-actions>
       </v-card>
 </template>

<script>

  import {store} from '../main.js'

  export default {
    name       : 'SignaturePad',
    methods    : {

      cancelSignature() {
          this.$refs.signaturePad.clearSignature();
          store.resetSignature();
          this.$emit('SignatureCanceled');
      },
      resetSignature() {
          this.$refs.signaturePad.clearSignature();
          store.resetSignature();
      },
      continueSignature() {
                    const { isEmpty, data = "" } = this.$refs.signaturePad.saveSignature();
                    var emitObject = {
                                       "signature" : data,
                                        "date"      : this.date,
                                        "name"      : this.name
                    };
                    store.saveSignature( emitObject);
                    this.$emit('SignatureUpdated', emitObject);
      },

    },
    mounted() {
            this.$nextTick(function () {
                 let engineer   = JSON.parse( localStorage.getItem("equalResponseEngineer") );
                 this.name      = (engineer.ENGINEER_NAME) ? engineer.ENGINEER_NAME : "N/A";
                 this.date      = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            })
    }, 
    data() {
      return {
          name   :  "",
          date   :  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
       };
    },
  }    
</script>

<style scoped>

    #signature {
      border            : double 3px transparent;
      border-radius     : 5px;
      background-image  : linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
      background-origin : border-box;
      background-clip   : content-box, border-box;
    }

</style>