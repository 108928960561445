<template>
   <div class="pa-3">
     <v-dialog  v-model="uploadingDialog.showDialog" persistent width="300">
            <v-card class="mx-auto" min-width="300">
                 <v-card-title class="primary white--text">Uploading Form</v-card-title>
                 <v-container style="height: 200px;">
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >

                    <v-col cols="12" class="text-subtitle-1 text-center">
                      <span>
                        <v-icon>mdi-file-pdf</v-icon>
                           {{ uploadingDialog.message }}
                      </span>  
                    </v-col>
                    
                    <v-col cols="8">
                      <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-col>

                  </v-row>
                 </v-container>
            </v-card>
     </v-dialog>

   </div>
</template>
  
<script>
   export default {
     props : { "uploadingDialog" : Object },
   } 
</script>