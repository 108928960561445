<template>

                    <div>

                        <v-snackbar
                          :timeout = "snackbar.timeout"
                          v-model = "snackbar.display"
                          absolute
                          outlined
                          rounded="pill"
                          color="primary"
                          top
                        >
                         {{snackbar.displayText}}
                        </v-snackbar>

                          <v-row dense>

                             <v-col>
                             <v-form ref="form" v-model="valid" lazy-validation>
                                <v-text-field  autofocus 
                                               dense 
                                               v-model = "engineer.ENGINEER_NAME"
                                               label = "Name of Engineer"
                                               outlined
                                               required
                                               :rules = "nameRules"
                                               @click:clear = "valid=false"
                                               clearable>
                                </v-text-field>
                                 <v-row class="d-flex justify-end" dense>
                                      <v-btn  class    =  "mr-1"     @click="resetForm"             color="primary">Reset</v-btn>
                                      <v-btn  :disabled = "!valid"   @click="updateEngineerName"    color="success">Update</v-btn>
                                 </v-row>
                              </v-form>
                             </v-col>

                            </v-row>

                          <!--
                            <v-row dense>
                              <v-col cols="8">
                               <v-text-field dense  v-model="engineer.ENGINEER_EMAIL" label="Email" outlined clearable></v-text-field>
                              </v-col>

                              <v-col cols="4">
                                  <v-btn class="nocapitalize" small @click="updateEngineerEmail" color="warning">Update Email</v-btn>
                              </v-col>
                           </v-row>
                           -->  

                    </div>

  
 </template>

<script>

import {store}        from '../main.js';

export default {
    name       : 'EngineerUpdate',
    methods    : {
                 showSnackbar( text, color="success" ) {
                        this.snackbar.displayText = text;
                        this.snackbar.display     = true;
                        this.snackbar.color       = color;
                 },

                 updateEngineerName(){


                            let   self        = this;
                            const  url       =  self.$restServer.updateURL;
                            const  update  =  { 
                                                "engineerId"    : this.engineer.ENGINEER_ID,
                                                "engineerName"  : this.engineer.ENGINEER_NAME
                                              };
                            store.consoleLog(`Updating Engineer name, EngineerId :'${this.engineer.ENGINEER_ID}'`);                  

                            self.$http.post(url,  update)
                            .then(  ( response ) => {
                                     if ( response.data.RECORD_UPDATED) { 
                                           self.showSnackbar("Engineer's name was updated successfully");
                                           var jsonStrEngineer =  JSON.stringify( this.engineer);
                                           if ( jsonStrEngineer ) 
                                                localStorage.setItem( "equalResponseEngineer", jsonStrEngineer);
                                           store.consoleLog(`Updated engineer name, EngineerId : '${this.engineer.ENGINEER_ID}'`);                  
                                    } else {
                                           throw `Update Engineer Name failed, EngineerId : '${this.engineer.ENGINEER_ID}`; 
                                    }
  
                            })
                            .catch( (e) => {
                                  self.showSnackbar(e, "red darken-2");
                                  store.consoleLog( e );
                            });
                 },
                 updateEngineerEmail(){
                            let   self       = this;
                            const  url      =  self.$restServer.updateURL;
                            const  update   =  { 
                                                "engineerId"    : this.engineer.ENGINEER_ID,
                                                "engineerEmail" : this.engineer.ENGINEER_EMAIL
                                              };
                            self.$http.post(url,  update)
                            .then(  (response) => {
                                     if ( response.data.RECORD_UPDATED ) { 
                                           self. showSnackbar("Enginner Email Updated !!");
                                           var jsonStrEngineer =  JSON.stringify(this.engineer);
                                           if ( jsonStrEngineer )
                                               localStorage.setItem( "equalResponseEngineer", jsonStrEngineer);
                        
                                    } else {
                                        throw "Update Record Failed"; 
                                    }
  
                            })
                            .catch( (e) => {
                                self. showSnackbar(e);
                                console.log(e);
                            });

                 },
                 resetForm(){
                      this.engineer   = JSON.parse( localStorage.getItem("equalResponseEngineer") );
                 },
                 
    },
    mounted() {
       this.$nextTick(function () {
            this.engineer   = JSON.parse( localStorage.getItem("equalResponseEngineer") );
      })
    },
    data() {
      return {
             snackbar          :  { displayText : "", display : false, timeout : 2000, color : ""},
             engineer          :  {},
             valid             :  false,
             nameRules         : [
                    v => !!v           || 'Name is required'
            ],
      };
    },
}    

</script>

<style scoped>
    .nocapitalize { text-transform: none !important; } 
</style>    
    