<!--
    this component added on 4-17-24 
    to display the rebrand form photos upload progress
    it uses 'uploadingPhotosDialog' an object passed in as property.
    from reBrand.vue (te parent) 
  -->

<template>

   <div class="pa-3">

    <v-dialog  v-model="uploadingRebrandDialog.showDialog" persistent width="400">

            <v-card class="mx-auto" min-width="400">
              <v-card-title class="primary white--text">Uploading Rebrand Form</v-card-title>
 
                 <v-card-text>
    
                    <v-col cols="12" class="text-subtitle-1">
                      <span  v-if = "uploadingRebrandDialog.pdfMessage" >
                         <v-icon>mdi-file-pdf</v-icon>
                         {{ uploadingRebrandDialog.pdfMessage }}
                      </span>  
                    </v-col>

                    <v-divider></v-divider>
                  
                    <v-col cols="12" class="text-subtitle-1">
                      <span v-if = "uploadingRebrandDialog.photosMessage">
                        <v-icon>mdi-file-pdf</v-icon>
                         {{ uploadingRebrandDialog.photosMessage }}
                      </span><br/>
                    </v-col>

                    <v-card-text v-if = "uploadingRebrandDialog.photosMessage">

                          <v-row class="align-center">
                            <v-col cols="5">Photos Uploading :</v-col>
                            <v-col cols="7">
                              <v-progress-linear
                              color="deep-purple accent-4"
                              indeterminate
                              rounded
                              height="6"
                            ></v-progress-linear>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="6">Metric</v-col>
                            <v-col cols="12" sm="6">Counter</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="6">Total Bytes</v-col>
                            <v-col cols="12" sm="6">{{uploadingRebrandDialog.totalBytes}}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="6">Loaded Bytes</v-col>
                            <v-col cols="12" sm="6">{{uploadingRebrandDialog.loadedBytes}}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="6">Percentage</v-col>
                            <v-col cols="12" sm="6">{{uploadingRebrandDialog.percentageCompleted}}</v-col>
                          </v-row>

                    </v-card-text>
                   

                 </v-card-text>


            </v-card>
    </v-dialog>

   </div>
</template>
  
<script>
   
   export default {
     props : { "uploadingRebrandDialog" : Object },
   } 

</script>