<template>

  <v-app id="main" :style="{background: $vuetify.theme.themes[theme].background}">

    <v-app-bar  app color="primary" dark>
     
      <div class="d-flex align-center">

         <a  href="https://equalresponse.co.uk/" target="_blank">
            <v-img
             alt="Vuetify Logo"
             class="shrink mr-2"
             contain
             src = "./assets/icon.png"
             transition="scale-transition"
             width="30">
           </v-img>
         </a>
         <span class="shrink mr-1 mt-1 hidden-sm-and-down">Engineer PWA</span>
         <span class="shrink  mt-1" >{{this.version}} - &copy; Equal Response Ltd {{ new Date().getFullYear() }}</span>
      </div>
 
      <v-spacer></v-spacer>

      <div v-if = "this.$router.currentRoute.path === '/'"> 
           <v-btn  v-if = "this.showByPassButton"     color="success" @click="byPass">Bypass</v-btn> 
      </div>

      <div v-if = "this.$router.currentRoute.path === '/engineer'" > 
           <v-btn v-if = "this.showManageUsersButton" color="success" @click="userManagementClick">Users</v-btn> 
      </div>



    </v-app-bar>

     <v-main>
       <v-container fluid>
           <router-view/>
       </v-container>
     </v-main>

     <v-footer app color="primary" bottom fixed dark padless>
        <v-col class="d-flex align-center justify-space-between">

            <v-btn  v-if = "this.$router.currentRoute.path != '/'" color="success" @click="goBack">
               <v-icon dark left >mdi-arrow-left</v-icon>Back
            </v-btn>
       
            <v-btn  v-if = "this.$router.currentRoute.path != '/'" color="success" @click="logOff">
               <v-icon dark left >mdi-logout</v-icon>Logoff
            </v-btn>

        </v-col>
     </v-footer>
  
  </v-app>

</template>



<script>

import {store} from './main.js'

export default {
  name    : 'app',
  methods : {

      userManagementClick(){
            this.$router.push("/userManagement");
      },
      logOff() {
            
             store.removeDatabase();
             store.resetPhotos();
             store.resetSignature();
             this.resetLocalStorage();
             this.$router.push("/");
      },
      goBack() {

             this.$router.back();
      },
      
       byPass() {
                  const   byPassEngineer = {
                                          "ENGINEER_ID"       : "gary",
                                          "ENGINEER_PASSWORD" : "mlwh2000",
                                          "ENGINEER_EMAIL"    : "gary@extragas.co.uk",
                                          "ENGINNER_NAME"     : "",
                                          "RECORD_FOUND"      : true,
                                          "EXCEPTION"         : ""
                  }
                                
                  const   byPassCustomer = {
                      "REQUEST_DATABASE": "ACCOUNT",
                      "REQUEST_INDEXFILE": "ACCOUNT",
                      "REQUEST_SEEKVALUE": "EXT01003",
                      "REQUEST_ERROR": "FALSE",
                      "RECORD_FOUND": "TRUE",
                      "ACCOUNT": "EXT01003",
                      "NAME": "Extrafuel Tester 1",
                      "NAMEXTN": "",
                      "ROADPREF": "Queensbury House",
                      "ROAD": "Harborough Road",
                      "DISTRICT": "Maidwell",
                      "TOWN": "Northampton",
                      "COUNTY": "Northamptonshire",
                      "POSTCODE": "NN6 9JA",
                      "CONTACT": "",
                      "TYPE": "01",
                      "INVACCT": "EXT01003",
                      "PAYACCT": "EXT01003",
                      "MAPREF": "",
                      "ROUTE": 0,
                      "PAGE": 0,
                      "CASH": "1",
                      "GROUP": 0,
                      "DELIVERY": "N",
                      "PHONE": "",
                      "PHONEH": "07801219868",
                      "PHONEW": "",
                      "FAX": "",
                      "TERMS": 7,
                      "ORDER": "N",
                      "LSTPART": "MISC",
                      "LSTQTY": 1,
                      "LSTORD": 533830,
                      "LSTDATE": "2020-09-03",
                      "OPENDB": 60,
                      "OPENCR": 0,
                      "OPENCSH": 0,
                      "OPENPDB": 0,
                      "OPENPCR": 0,
                      "OPENPCSH": 0,
                      "DBNMBR": 0,
                      "PRNTDN": "Y",
                      "PRNTINV": "E",
                      "IT": 6,
                      "CARFREE": 0.04,
                      "CREDLIM": 750,
                      "MINORD": 0,
                      "OPENORD": 0,
                      "NMBR": "",
                      "CDISC": 0,
                      "T": "D",
                      "ADDED": "2014-09-23",
                      "EDITED": "2020-10-05",
                      "SETTLE": 0,
                      "SALESMAN": "COR",
                      "REBATE_REF": "",
                      "INVTYPE": 6,
                      "EEC": "N",
                      "BR": 4,
                      "ACCBAND": "FIX325",
                      "OTT": "Y",
                      "STATEMNT": "E",
                      "STATTYPE": 1,
                      "COUNTRY": "",
                      "CLASS": "C",
                      "STERM": 30,
                      "CCRED": 0,
                      "TR": 0,
                      "SUPPSETTLE": 0,
                      "ACCTCONT": "",
                      "ACCTCPHONE": "",
                      "GENCONT": "",
                      "GENCPHONE": "",
                      "ST": "D",
                      "FACTORED": "N",
                      "USER1": "AG,AG",
                      "USER2": "",
                      "RC": "",
                      "NOOTT": "N",
                      "CHKPOINTS": 0,
                      "OPENORD_Y": "",
                      "CURRENCY": "826",
                      "CLASSEXT": "T",
                      "SOURCE": "",
                      "SUPP_REF": "",
                      "VATNO": "",
                      "OPENORD_P": "1",
                      "BATCH_OPEN": "Y",
                      "HAS_SETTLE": "Y",
                      "PURCH_NOM": 0,
                      "MAINT_DATE": "2014-09-23",
                      "IDS_CUST": "N",
                      "IDS_SUPP": "N",
                      "EMAIL": "jw@webs.limited, tom@awebserver.co.uk",
                      "INVORDER": "",
                      "CARR_VAL": "",
                      "SUBCONT": "N",
                      "SUPPID": 0,
                      "FIRSTSALE": "2014-09-28",
                      "RC2": "12",
                      "RC3": "CP",
                      "CMETHOD": "",
                      "OPENORDBR": 0,
                      "NO_TERMS": "N",
                      "CHNGACCT": "",
                      "CHNGDATE": "- -",
                      "NOMSALE": 0,
                      "USER3": "450,450",
                      "USER4": "",
                      "USER5": "263702,263652",
                      "USER6": "31/12/2017,31/12/2017",
                      "PART": "BLKRN",
                      "CARRBAND": "",
                      "QBORDVAL": 0,
                      "PO_OPTIONS": "0.0",
                      "OPT_PO": "N",
                      "GET_POINTS": "",
                      "HIREXCWKND": "N",
                      "PO_CANCEL": "Y",
                      "SAGEDEPT": 0,
                      "MOBILE": "",
                      "MAXATTACH": 0,
                      "STOPREASON": "",
                      "USER7": "",
                      "USER8": "tankmonitoring@extragas.co.uk",
                      "USER9": "",
                      "EMAIL_INV": "",
                      "EMAIL_STAT": "",
                      "CHKPTSDATE": "- -",
                      "BATCH_LTRS": "Y",
                      "STLCNOO": "N",
                      "RATE": 0,
                      "USERDATE1": "2017-08-21",
                      "USERDATE2": "2018-08-21",
                      "LIMIT_FLAG": "",
                      "ACCBAND2": "",
                      "DEF_VAT": "",
                      "COUN_CODE": "",
                      "PIPEDRIVE": "",
                      "GDPR_OPTIN": "Y",
                      "POINT_MULT": "",
                      "GDPR_NATUR": "N",
                      "GDPR_AGE16": "N",
                      "GDPR_BY": "",
                      "GDPR_STAMP": "",
                      "SLC_PERC": 0,
                      "CARDCHECK": "",
                      "LATITUDE": "",
                      "LONGITUDE": "",
                      "EORI": "",
                      "INCOTERMS": ""
                  };

                  localStorage.setItem("equalResponseEngineer",    JSON.stringify(byPassEngineer) );
                  localStorage.setItem("equalResponseCustomer",    JSON.stringify(byPassCustomer) );

                  store.setManageUsersPermission(  true );
                  store.setFormsAllowed( JSON.parse( '[{"text":"All","value":"all","disabled":false}]' ) );
                  
                  this.$router.push( { name: 'Engineer', "params" : { "isByPass" : true} } );

       
       }
  },
  computed:{
    theme(){
       let test = (this.$vuetify.theme.dark) ? 'dark' : 'light';
       return test;
    },
    showByPassButton() {
       return ( store.showByPassButton );
    },
    showManageUsersButton() {
       return ( store.showManageUsersButton );
    },
    
    version() {
       return ( store.version );
    }
  },
  data: () => ({
      links: [
        'Home',
        'Camera',
        'Team',
      ],
    }),
};
</script>


<style scoped>

  .router_link {
    color:white

}
   .router_link:hover,
   .router_link:active{
      background-color: indianred;
      cursor: pointer;
    }

</style>
