<template>
        <v-menu
            v-model="fromTimeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y 
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
             <v-text-field
                :label    = "caption"
                :disabled = "!enabled"
                dense
                outlined
                readonly
                clearable
                v-on   = "on"
                :value = "curTime"
              ></v-text-field>
            </template>

            <v-time-picker
              locale     = "en-in"
              :value     = "curTime"
              scrollable
              @change   = "timeSelected"
            ></v-time-picker>
       </v-menu>
</template>

<script>
  export default {
    props      : [ 'curTime', 'caption','enabled'],
    methods    : {
                 timeSelected(e) {
                      this.fromTimeMenu = false;
                      this.$emit('updated', e)
                 }
    },

    data() {
      return {
          fromTimeMenu  : false,
      };
    },
  };
</script>