<template>
      <v-expansion-panel class="expansion_panel mb-1"> 
        <v-expansion-panel-header>Customer </v-expansion-panel-header>
         <v-expansion-panel-content>
          <v-form>
            <v-row no-gutters>
               <v-col class="pa-1" cols="12" sm="6">
                              <v-text-field  readonly dense outlined v-model= "customer.NAME"           label="Customer Name"> </v-text-field>
                              <v-text-field  readonly dense outlined v-model= "customer.ACCOUNT"        label="Customer ID"> </v-text-field>
                              <v-textarea    readonly dense outlined v-model= "formatCustomerAddress"   label="Address"></v-textarea>
               </v-col>

               <v-col class="pa-1" cols="12" sm="6">
                             <v-text-field  readonly dense outlined v-model= "customer.POSTCODE"      label="Postcode"></v-text-field>
                             <v-text-field  readonly dense outlined v-model= "customer.PHONEH"        label="Home Phone"></v-text-field>
                             <v-text-field  readonly dense outlined v-model= "customer.PHONEM"        label="Mobile Phone"></v-text-field>
               </v-col>
            </v-row>
          </v-form>
         </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
  export default {
     name        : 'Customer',
     props       : { "customer" : Object },
     computed    : {

                    formatCustomerAddress() {
                                  let address =    this.$props.customer.NMBR  + " " +
                                                   this.$props.customer.ROAD  + ", " +
                                                   this.$props.customer.DISTRICT + ", " +
                                                   this.$props.customer.TOWN + ", " +
                                                   this.$props.customer.COUNTY;
                                  return address;                 
                    }

     },
  }
</script>
