import Vue     from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors  from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: { 
    dark: false,
    themes: {
      light: {
        primary:     "#006fa5",
        secondary:   colors.grey.darken1,
        accent:      colors.shades.black,
        error:       colors.red.accent3,
        background:  "#f5f5f5",
      },
      dark: {
        primary:      colors.blue.lighten3, 
        background:   colors.indigo.base, 
      },
    },
  },
})